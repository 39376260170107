<template>
  <div class="home-container">
    <div class="home-header-container">
      <div class="home-header-upper-container">
        <div class="home-header-background"/>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="7" xl="5" style="z-index: 1;">
              <div class="home-header-upper-text-container">
                <div><small>Super Rent</small></div>
                <h1 class="home-header-title">SUPERleichte, digitale Fahrzeugvermietung</h1>
                <div class="home-header-subtitle">
                  Werde superschnell & supergünsti ein Teil der SUPER Familie. <br/>
                  Erträge durch das Sharing deiner Fahrzeuge.
                </div>
                <ul>
                  <li>
                    Viele Neukunden durch die unkomplizierte & selbsterklärende  Renting APP
                  </li>
                  <li>
                    Shared mobility, saves our planet! Gemeinsam mit Dir!
                  </li>
                  <li>
                    Super leichte SaaS Renting White Label Lösung
                  </li>
                  <li>
                    Erfassung von Fahrzeugdaten wie Standort, Kraftstoff/Batteriestand, Leistung 100%
                    Diebstahlschutz & DSVGO konform
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-container>
      <price/>
      <benefits/>
      <div class="customer-container">
        <div class="customer-inner-container">
          <v-row>
            <v-col class="align-self-center text-center" sm="4" md="4" lg="4" xl="4">
              <strong>Unsere Kunden</strong>
            </v-col>
            <v-col class="justify-center align-self-center" sm="4" md="4" lg="4" xl="4">
              <v-img class="logo" src="../../public/images/customers/GRUEN4RENT_LOGO.png" contain/>
            </v-col>
            <v-col class="phone-container" sm="4" md="4" lg="4" xl="4">
              <v-img class="phone" src="../../public/images/GRUEN4RENT_PHONE.png"/>
            </v-col>
          </v-row>
        </div>
      </div>
      <overview/>
      <videos/>
      <contact id="contact"/>
      <div class="home-innovation">
        <h2><span class="text--black">Unsere Innovation</span></h2>
        <div class="home-innovation-image-container">
          <v-img class="phone" src="../../public/images/EBWM_Banner_E-Bike_License_062022_01.png"
                 v-if="$vuetify.breakpoint.lgAndDown"/>
          <v-img class="phone" src="../../public/images/EBWM_Banner_E-Bike_License_062022_01_2x.png"
                 v-else/>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { mdiChevronDown } from '@mdi/js';
  const Price = () => import(/* webpackChunkName: "Home-Price" */ '@/components/home/Price.vue');
  const Benefits = () => import(/* webpackChunkName: "Home-Benefits" */ '@/components/home/Benefits.vue');
  const Overview = () => import(/* webpackChunkName: "Home-Overview" */ '@/components/home/Overview.vue');
  const Videos = () => import(/* webpackChunkName: "Home-Videos" */ '@/components/home/Videos.vue');
  const Contact = () => import(/* webpackChunkName: "Home-Contact" */ '@/components/home/Contact.vue');

  export default {
  name: 'Home',
  data: () => ({
    icons: {
      down: mdiChevronDown,
    },
    car: null,
    carPosition: null,
    tracker: false
  }),
  components: {
    Price, Benefits, Overview, Contact, Videos,
  },
  mounted() {
    this.globalEvents.$on('goToElement', (selector) => {
      if (selector !== undefined) {
        this.$vuetify.goTo(selector, {
          duration: 250,
          offset: 87,
          easing: 'easeInOutCubic',
        });
      }
    });
    if (this.$vuetify.breakpoint.smAndDown) {
      this.tracker = true;
    }
  },
  methods: {
    goTo(selector) {
      if (selector !== undefined && selector !== null) {
        this.globalEvents.$emit('goToElement', selector);
      }
    },
    animateSuperTracker(open) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        if (!this.tracker) {
          const supertracker1 = document.getElementById('supertracker_1');
          if (supertracker1.classList.contains('hovering')) {
            supertracker1.classList.remove('hovering');
          } else {
            supertracker1.classList.add('hovering');
          }
        }
        setTimeout(() => {
          this.tracker = open;
        }, 300);
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/variables.scss";
  @import "../assets/scss/layout.scss";
  .home-container {
    color: white;
    font-weight: 300;
    position: relative;
    .home-header-container {
      overflow: hidden;
      position: relative;
      .home-header-background {
        background: #000000 0 0 no-repeat padding-box;
        opacity: 0.65;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .home-header-upper-container {
        background: url("../../public/images/SUR_Headerbild_LARGE.jpg") no-repeat;
        background-size: cover;
        height: 600px;
        @include MQ(XL) {
          height: 650px;
          background-position:bottom left;
        }
        @include MQ(L) {
          height: 650px;
          background-position:bottom left;
        }
        @include MQ(M) {
          height: 550px;
        }
        @include MQ(S) {
          height: 500px;
          background: url("../../public/images/SUR_Headerbild_SMALL.jpg") 40% no-repeat;
          background-size: cover;
        }
        @include MQ(XS) {
          height: 500px;
          background: url("../../public/images/SUR_Headerbild_SMALL.jpg") 20% no-repeat;
          background-size: cover;
        }
        .home-header-upper-text-container {
          margin-top: 150px;
          @include MQ(L) {
            margin-top: 200px;
          }
          @include MQ(S) {
            margin-top: 100px;
          }
          @include MQ(XS) {
            margin-top: 80px;
          }
          .home-header-title {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 65px;
            line-height: 68px;
            font-weight: normal;
            @include MQ(XS) {
              margin-top: 10px;
              font-size: 40px;
              line-height: 43px;
            }
          }
          .home-header-subtitle {
            font-weight: normal;
            font-size: 20px;
            color: $secondary-text;
            @include MQ(XS) {
              font-size: 18px;
            }
          }
          ul {
            margin-top: 10px;
            li {
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              text-shadow: 8px 3px 6px #00000029;
              margin-bottom: 10px;
            }
          }
        }
        .home-header-teaser {
          margin-top: 150px;
          max-width: 650px;
          @include MQ(L) {
            margin-top: 240px;
          }
        }
      }
      .home-header-center-container {
        height: 450px;
        margin-top: -125px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include MQ(M) {
          height: 350px;
          margin-top: -75px;
        }
        @include MQ(S) {
          height: 350px;
          margin-top: -25px;
        }
        @include MQ(XS) {
          height: 225px;
          margin-top: -25px;
        }
        .home-header-center-hover-container {
          height: 450px;
          width: 600px;
          position: absolute;
          @include MQ(XL) {
            width: 700px;
          }
          @include MQ(M) {
            height: 350px;
            margin-top: -75px;
          }
          @include MQ(S) {
            height: 350px;
            margin-top: -25px;
          }
          @include MQ(XS) {
            height: 225px;
            margin-top: -25px;
          }
        }
        .home-super-tracker-animation-1 {
          transition: transform 0.8s;
          &.hovering {
            transform: rotate(360deg);
          }
          @include MQ(M) {
            max-height: 80px !important;
            max-width: 80px !important;
          }
        }
        .home-super-tracker-animation-2 {
          margin-left: 150px;
          margin-top: -80px;
          animation: fadeIn 2s;
          @include MQ(M) {
            margin-left: 120px;
            margin-top: -70px;
          }
          @include MQ(S) {
            margin-left: 0;
            margin-top: -60px;
          }
          @include MQ(XS) {
            margin-left: 0;
            margin-top: -60px;
          }
        }
        @keyframes fadeIn {
          0% {opacity:0;}
          100% {opacity:1;}
        }
      }
    }
    .customer-container {
      margin-top: 100px;
      margin-bottom: 150px;
      padding-left: 200px;
      padding-right: 200px;
      @include MQ(L) {
        margin-top: 70px;
        margin-bottom: 100px;
      }
      @include MQ(M) {
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 70px;
        margin-bottom: 100px;
      }
      @include MQ(S) {
        padding-left: 40px;
        padding-right: 40px;
      }
      @include MQ(XS) {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 25px;
        margin-bottom: 50px;
      }
      .customer-inner-container {
        border: 3px solid #EBF3F6;
        border-radius: 15px;
        color: #444444;
        font-size: 15px;
        padding: 20px;
        strong {
          font-weight: 700 !important;
          opacity: 0.5;
        }
        .logo {
          height: 50px;
          @include MQ(L) {
            height: 40px;
          }
          @include MQ(M) {
            height: 40px;
          }
          @include MQ(S) {
            height: 40px;
          }
          @include MQ(XS) {
            height: 40px;
            margin-top: 25px;
          }
        }
        .phone-container {
          position: relative;
          .phone {
            position: absolute;
            top: -120px;
            left: 50%;
            transform: translateX(-50%);
            @include MQ(L) {
              top: -60px;
              width: 200px;
            }
            @include MQ(M) {
              top: -60px;
              width: 180px;
            }
            @include MQ(S) {
              top: -60px;
              width: 180px;
            }
            @include MQ(XS) {
              position: relative;
              top: 0;
              width: 180px;
            }
          }
        }
      }
    }
    .home-innovation {
      z-index: 1;
      position: relative;
      margin-top: 100px;
      @include MQ(XS) {
        margin-top: 50px;
        width: 100%;
      }

      h2 {
        font-weight: 400;
        font-size: 35px;
        line-height: 38px;
        margin-bottom: 50px;
        padding-left: 200px;
        padding-right: 200px;
        .text--black {
          color: black;
          font-weight: 700;
        }
        @include MQ(M) {
          font-size: 28px;
          line-height: 31px;
          padding-left: 40px;
          padding-right: 40px;
        }
        @include MQ(S) {
          padding-left: 40px;
          padding-right: 40px;
        }
        @include MQ(XS) {
          font-size: 30px;
          line-height: 33px;
          margin-bottom: 40px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .home-innovation-image-container {
        margin-left: -24px;
        margin-right: -48px;
        @include MQ(XS) {
          width: 100%;
          margin: 0;
        }
        @include MQ(S) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
</style>
